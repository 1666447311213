// Step 1: Import React
import * as React from 'react'

// Step 2: Define your component
const Page = () => {
  return (
    <main>
      <h1>Page not found</h1>
      Please return
    </main>
  )
}

// You'll learn about this in the next task, just copy it for now
export const Head = () => <title>Page Not Found</title>

// Step 3: Export your component
export default Page